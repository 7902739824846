import axios from "axios";
import authHeader from "./auth-header";
import multipartAuthHeader from "./auth-header-multipart";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class ProfileService {
  getProfileInfo(userId) {
    return axios.get(BASE_URL + "api/user/profile/userProfile/getProfileInfo", {
      // return axios.get(  "api/user/profile/userProfile/getProfileInfo", {
      params: { userId },
      headers: authHeader(),
    });
  }

  getProfileInfoByCid(cid) {
    return axios.get(
      BASE_URL + "api/user/profile/userProfile/getProfileInfoByCid",
      // "api/user/profile/userProfile/getProfileInfoByCid",
      {
        params: { cid },
        headers: authHeader(),
      }
    );
  }

  receiveOtp(data) {
    return axios.post(
      BASE_URL + "api/user/profile/userProfile/receiveOtp",
      // "api/user/profile/userProfile/receiveOtp",
      data,
      { headers: authHeader() }
    );
  }

  changeMobileNo(data) {
    return axios.post(
      BASE_URL + "api/user/profile/userProfile/changeMobileNo",
      // "api/user/profile/userProfile/changeMobileNo",
      data,
      { headers: authHeader() }
    );
  }

  checkEmailExistOrNot(email) {
    return axios.get(
      BASE_URL + "api/user/profile/userProfile/checkEmailExistOrNot",
      // "api/user/profile/userProfile/checkEmailExistOrNot",
      {
        params: { email },
        headers: authHeader(),
      }
    );
  }

  receiveEmailVcode(data) {
    return axios.post(
      BASE_URL + "api/user/profile/userProfile/receiveEmailVcode",
      // "api/user/profile/userProfile/receiveEmailVcode",
      data,
      { headers: authHeader() }
    );
  }

  changeEmail(data) {
    return axios.post(
      BASE_URL + "api/user/profile/userProfile/changeEmail",
      // "api/user/profile/userProfile/changeEmail",
      data,
      { headers: authHeader() }
    );
  }

  changePassword(data) {
    return axios.post(
      BASE_URL + "api/auth/securitySetting/changePassword",
      // "api/auth/securitySetting/changePassword",
      data,
      { headers: authHeader() }
    );
  }

  changeParentInfo(data) {
    return axios.post(
      BASE_URL + "api/user/profile/userProfile/changeParentInfo",
      // "api/user/profile/userProfile/changeParentInfo",
      data,
      { headers: authHeader() }
    );
  }

  changeGuardianInfo(data) {
    return axios.post(
      BASE_URL + "api/user/profile/userProfile/changeGuardianInfo",
      // "api/user/profile/userProfile/changeGuardianInfo",
      data,
      { headers: authHeader() }
    );
  }

  changeSocialMediaLink(data) {
    console.log("here is the data");
    console.log(data);
    return axios.post(
      BASE_URL + "api/user/profile/userProfile/changeSocialMediaLink",
      // "api/user/profile/userProfile/changeSocialMediaLink",
      data,
      { headers: authHeader() }
    );
  }

  changeCurrentAddress(data) {
    return axios.post(
      BASE_URL + "api/user/profile/userProfile/changeCurrentAddress",
      // "api/user/profile/userProfile/changeCurrentAddress",
      data,
      { headers: authHeader() }
    );
  }

  changeUsername(data) {
    return axios.post(
      BASE_URL + "api/user/profile/userProfile/changeUsername",
      // "api/user/profile/userProfile/changeUsername",
      data,
      { headers: authHeader() }
    );
  }

  searchUser(searchKey) {
    return axios.get(BASE_URL + "api/user/profile/userProfile/searchUser", {
      // return axios.get("api/user/profile/userProfile/searchUser", {
      params: {
        searchKey,
      },
      headers: authHeader(),
    });
  }

  downloadFile(url) {
    return axios.get(
      BASE_URL + "api/user/profile/userProfile/downloadFile",

      { params: { url }, headers: authHeader() }

    );
  }

  resetUserPassword(data) {
    return axios.post(
      BASE_URL + "api/auth/securitySetting/resetUserPassword",
      // "api/auth/securitySetting/resetUserPassword",
      data,
      { headers: authHeader() }
    );
  }

  changeProfilePic(data) {
    return axios.post(
      BASE_URL + "api/user/profile/userProfile/changeProfilePic",
      // "api/user/profile/userProfile/changeProfilePic",
      data,
      {
        headers: multipartAuthHeader(),
      }
    );
  }

  getCensusImage(cid) {
    return axios.get(BASE_URL + "api/user/profile/userProfile/getCensusImage", {
      params: { cid },
      headers: authHeader(),
    });
  }

  getNotRegisteredUserCountByYear(year,dateString) {
    return axios.get(
        BASE_URL + "api/user/profile/userProfile/getNotRegisteredUserCountByYear",

        { params: { year,dateString }, headers: authHeader() }

    );
  }
  getWhiteListCensusImage(cid) {
    return axios.get(BASE_URL + "api/user/profile/whiteList/getCensusImage", {
      params: { cid },
      headers: authHeader(),
    });
  }
}

export default new ProfileService();
