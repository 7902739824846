import axios from "axios";
import authHeader from "./auth-header";
const BASE_URL = process.env.REACT_APP_BASE_URL + "api/training/management/common";
const getAll = () => {
    return axios.get(BASE_URL + "/getAllDzongkhags",
    // return axios.get("api/training/management/common" + "/getAllDzongkhags",
        {
            headers: authHeader()
        });
};

const getGeogByDzongkhagId = (dzongkhagId) => {
    return axios.get(BASE_URL + "/getGeogByDzongkhagId"
    // return axios.get("api/training/management/common" + "/getGeogByDzongkhagId"
        , {
            params: {
                dzongkhagId
            },
            headers: authHeader()
        });
}
const getAllGeogs = () => {
    return axios.get(BASE_URL + "/getAllGeogs",
        {
            headers: authHeader()
        });
};


export default {
    getAll,
    getGeogByDzongkhagId,
    getAllGeogs
};