import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    IconButton,
    MenuItem,
    Slide,
    Stack,
    Typography,
} from "@mui/material";
import {useNavigate} from "react-router";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import "react-quill/dist/quill.snow.css";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import {useDispatch} from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, {useEffect, useState} from "react";
import {changeTopBarTitle} from "../../redux/TopBarTitleSetter";
import {useFormik} from "formik";
import {DataGrid} from "@mui/x-data-grid";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {CustomNoRowsOverlay} from "../../Overlay/CustomNoRowOverlay";
import useComponentBasePremission from "../../CustomHooks/useComponetBasePermission";
import PageTransitionFadeIn from "../animation/PageAnimation/PageAnimation";
import * as XLSX from "xlsx";
import bulkOGZListService from "../../services/bulkOGZList.service";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import {fetchDzongkhags, handleDzongkhagChange} from '../utils/AddressUtils';
import OGZListSchema from "./OGZListSchema";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const NewOGZList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dzongkhags, setDzongkhags] = useState([]);
    const [alertData, setAlertData] = useState({severity: "info", message: ""});
    const permissions = useComponentBasePremission("ogzList");
    const [showUploadExcelDialog, setShowUploadExcelDialog] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showAddNewDialog, setShowAddNewDialog] = useState(false);
    const [gewogsByDzongkhag, setGewogsByDzongkhag] = useState([]);
    const [showAlert, setShowAlert] = useState(false);

    const [openFileInstruction, setOpenFileInstruction] = useState(true);
    const [loading, setLoading] = useState(false);
    const [notifySuccess, setNotifySuccess] = useState(false);

    const {values, setFieldValue, handleSubmit, resetForm, touched, errors} =
        useFormik({
            initialValues: {
                personalNo: "",
                name: "",
                dob: "",
                gender: "",
                dzongkhag: "",
                gewog: "",
                village: "",
                fatherId: "",
                motherId: "",
                mobileNo: "",
            },
            onSubmit: (values) => {
                create(values);
            },
            validationSchema: OGZListSchema,
        });

    const create = (fields) => {
        setLoading(true);
        const selectedDzongkhag = dzongkhags.find(dzongkhag => dzongkhag.dzongkhagId === fields.dzongkhag);
        const selectedGewog = gewogsByDzongkhag.find(gewog => gewog.geogId === fields.gewog);
        const updatedFields = {
            ...fields,
            dzongkhag: selectedDzongkhag ? selectedDzongkhag.dzongkhagName : null,
            gewog: selectedGewog ? selectedGewog.geogName : null,
        };
        bulkOGZListService.save(updatedFields).then((res) => {
            setLoading(false);
            if (res.status !== 208) {
                navigate("/authenticatedViewWrapper/ogzList");
            } else {
                setAlertData({severity: 'warning', message: res.data})
                setShowAlert(true)
            }


        })
            .catch(() => {
                setAlertData({severity: 'error', message: 'Error: Failed to save enlistment date.'})
                setShowAlert(true);
            });

    }

    const [fileData, setFileData] = useState([]);
    const columns = [
        {field: "personalNo", headerName: "Personal No", width: 100, editable: true},
        {field: "name", headerName: "Name", width: 200, editable: true},
        {field: "dob", headerName: "DOB", width: 100, editable: true},
        {field: "gender", headerName: "Gender", width: 100, editable: true},
        {field: "dzongkhag", headerName: "Dzongkhag", width: 200, editable: true},
        {field: "gewog", headerName: "Gewog", width: 200, editable: true},
        {field: "village", headerName: "Village", width: 200, editable: true},
        {field: "fatherId", headerName: "Father's ID", width: 200, editable: true},
        {field: "motherId", headerName: "Mother's ID", width: 200, editable: true},
        {field: "mobileNo", headerName: "Mobile Number", width: 200, editable: true},
    ];
    const [selectionModel, setSelectionModel] = useState([]);

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    };

    const handleDeleteSelectedRows = () => {
        // Filter out the selected rows from fileData
        const updatedFileData = fileData.filter(
            (row) => !selectionModel.includes(row.id)
        );

        // Update state with the new fileData
        setFileData(updatedFileData);

        // Clear selectedRows state
        setSelectionModel([]);
    };

    const isAtLeastOneRowSelected = selectionModel.length > 0;

    const sendBulkUpload = () => {
        // Access the selected rows using the selectionModel
        setLoading(true);
        const selectedRows = selectionModel.map((id) =>
            fileData.find((row) => row.id === id)
        );

        let data = {ogzListDtos: selectedRows};
        bulkOGZListService
            .bulkUploadOGZList(data)
            .then((response) => {
                setLoading(false);
                setNotifySuccess(true);
            })
            .catch((error) => {
                setLoading(false);
                setAlertData({
                    severity: "warning",
                    message: error.response.data.message,
                    show: true,
                });
            });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, {type: "array"});
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet);

                if (jsonData.length > 0) {
                    // Define the columns you want to keep (name, email, mobileNo)
                    const desiredColumns = [
                        "personalNo",
                        "name",
                        "dob",
                        "gender",
                        "dzongkhag",
                        "gewog",
                        "village",
                        "fatherId",
                        "motherId",
                        "mobileNo",
                    ];
                    const desiredColumnsLowerCase = desiredColumns.map(col => col.toLowerCase());
                    console.log(desiredColumnsLowerCase)
                    // Filter out unnecessary columns
                    const filteredData = jsonData.map((row, index) => {
                        const filteredRow = {};

                        // Iterate through each key in the row
                        Object.keys(row).forEach((key) => {
                            const lowerCaseKey = key.toLowerCase();
                            // Check if the lowercase key is in the desiredColumns array
                            if (desiredColumnsLowerCase.includes(lowerCaseKey)) {
                                // Find the original desired column name (preserve casing)
                                const originalKey = desiredColumns.find(col => col.toLowerCase() === lowerCaseKey);

                                // Convert fatherId, motherId, and mobileNo to string before assigning
                                if (originalKey === "fatherId" || originalKey === "motherId" || originalKey === "mobileNo") {
                                    filteredRow[originalKey] = String(row[key]);  // Convert to string
                                } else {
                                    if (originalKey === "gender") {
                                        filteredRow[originalKey] = row[key] === "Male" ? 'M' : (row[key] === "Female" ? 'F' : row[key]);
                                    } else {
                                        filteredRow[originalKey] = row[key];
                                    }

                                }
                            }
                        });

                        return {...filteredRow, id: index};
                    });

                    setFileData(filteredData);
                }
            };
            reader.readAsArrayBuffer(file);
            setShowUploadExcelDialog(false);
        }
    };

    const handleUploadExcelDialog = (event, reason) => {
        if (reason && reason == "backdropClick") return;
        if (event && event.key === "Escape") {
            event.stopPropagation();
            event.preventDefault();
            return;
        }
        setShowUploadExcelDialog(false);
    };

    const handleNotifyNowDialog = (event, reason) => {
        if (reason && reason == "backdropClick") return;
        if (event && event.key === "Escape") {
            event.stopPropagation();
            event.preventDefault();
            return;
        }
    };

    useEffect(() => {
        dispatch(changeTopBarTitle("New OGZ List"));
        fetchDzongkhags(setDzongkhags);
    }, []);

    useEffect(() => {
        // Trigger selection after a short delay
        const timeoutId = setTimeout(() => {
            setSelectionModel(fileData.map((row) => row.id));
        }, 100);

        return () => clearTimeout(timeoutId);
    }, [fileData]);

    return (
        <PageTransitionFadeIn
            child={
                <>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                    >
                        <Stack direction="row" spacing={1} justifyContent="flex-start">
                            <Button
                                style={{textTransform: "initial"}}
                                size="small"
                                variant="outlined"
                                onClick={() => navigate(-1)}
                            >
                                <ChevronLeftOutlinedIcon/> <Typography>Go Back</Typography>
                            </Button>
                            <Typography variant="h5">
                                OGZ List
                            </Typography>
                        </Stack>
                        {permissions[0].save && (
                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                <Button
                                    style={{textTransform: "initial"}}
                                    size="small"
                                    variant="outlined"
                                    color="info"
                                    onClick={() => {
                                        setShowAddNewDialog(true);
                                    }}
                                >
                                    <Typography>Add New</Typography>
                                </Button>
                                <Button
                                    style={{textTransform: "initial"}}
                                    size="small"
                                    variant="outlined"
                                    color="info"
                                    endIcon={<FileUploadOutlinedIcon/>}
                                    onClick={() => {
                                        setShowUploadExcelDialog(true);
                                        setOpenFileInstruction(true);
                                    }}
                                >
                                    <Typography>Upload Excel File</Typography>
                                </Button>
                            </Stack>
                        )}
                    </Stack>

                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        marginTop={2}
                    >
                        {isAtLeastOneRowSelected && (
                            <>
                                <Button
                                    style={{textTransform: "initial"}}
                                    size="small"
                                    variant="outlined"
                                    color="info"
                                    endIcon={<CommentOutlinedIcon/>}
                                    onClick={() => {
                                        setShowConfirmDialog(true);
                                    }}
                                >
                                    <Typography>Upload</Typography>
                                </Button>

                                <Button
                                    style={{textTransform: "initial"}}
                                    size="small"
                                    variant="outlined"
                                    startIcon={<DeleteForeverIcon/>}
                                    onClick={handleDeleteSelectedRows}
                                    color="error"
                                >
                                    <Typography>Delete</Typography>
                                </Button>
                            </>
                        )}
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                        marginTop={1}
                    >
                        <DataGrid
                            checkboxSelection
                            autoHeight
                            density="compact"
                            rows={fileData}
                            columns={columns.map((col) => ({
                                ...col,
                                editable: true,
                            }))}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                            selectionModel={selectionModel}
                            onSelectionModelChange={handleSelectionModelChange}
                        />
                    </Stack>

                    <Dialog
                        TransitionComponent={Transition}
                        fullWidth={true}
                        open={showAddNewDialog}
                        onClose={() => setShowAddNewDialog(false)}
                        aria-labelledby="alert-dialog-title"
                    >
                        <DialogContent>
                            <Stack width="100%" display="flex" marginBottom={2}>
                                <Typography variant="h5">Please fill up the details</Typography>
                            </Stack>
                            <Stack width="100%" display="flex" marginBottom={2}>
                                <TextField
                                    fullWidth
                                    placeholder="Full Name"
                                    label="Full Name"
                                    required
                                    size="small"
                                    inputProps={{maxLength: 200, autoComplete: "off"}}
                                    value={values.name}
                                    onChange={(e) =>
                                        setFieldValue("name", e.target.value)
                                    }
                                    error={touched.name && errors.name}
                                    helperText={touched.name && errors.name}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2} width="100%" marginBottom={2}>
                                <TextField
                                    fullWidth
                                    placeholder="Personal Number"
                                    label="Personal Number"
                                    required
                                    size="small"
                                    inputProps={{maxLength: 200, autoComplete: "off"}}
                                    value={values.personalNo}
                                    onChange={(e) => setFieldValue("personalNo", e.target.value)}
                                    error={touched.personalNo && errors.personalNo}
                                    helperText={touched.personalNo && errors.personalNo}
                                />
                                {/* date of birth */}
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box>
                                        <DatePicker
                                            inputProps={{autoComplete: "off"}}
                                            views={["year", "month", "day"]}
                                            label={"Date of Birth"}
                                            inputFormat="DD/MM/YYYY"
                                            maxDate={new Date()}
                                            value={values.dob}
                                            onChange={(selectedDate) => {
                                                try {
                                                    setFieldValue("dob", new Date(selectedDate));
                                                } catch (err) {
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    size="small"
                                                    {...params}
                                                    fullWidth
                                                    error={touched.dob && errors.dob}
                                                    helperText={touched.dob && errors.dob}
                                                />
                                            )}
                                        />
                                        <Box height={5}/>
                                        <Typography variant="body2" sx={{color: "grey"}}>
                                            {"Format"} {": dd/mm/yyyy"}
                                        </Typography>
                                    </Box>
                                </LocalizationProvider>
                            </Stack>
                            <Stack direction="row" spacing={2} width="100%" marginBottom={2}>
                                <TextField
                                    select
                                    fullWidth
                                    placeholder="Gender"
                                    label="Gender"
                                    required
                                    size="small"
                                    inputProps={{maxLength: 200, autoComplete: "off"}}
                                    value={values.gender}
                                    onChange={(e) => setFieldValue("gender", e.target.value)}
                                    error={touched.gender && errors.gender}
                                    helperText={touched.gender && errors.gender}
                                >
                                    <MenuItem value="M">Male</MenuItem>
                                    <MenuItem value="F">Female</MenuItem>
                                </TextField>
                                <TextField
                                    fullWidth
                                    placeholder="Mobile Number"
                                    label="Mobile Number"
                                    required
                                    size="small"
                                    inputProps={{maxLength: 200, autoComplete: "off"}}
                                    value={values.mobileNo}
                                    onChange={(e) =>
                                        setFieldValue("mobileNo", e.target.value)
                                    }
                                    error={touched.mobileNo && errors.mobileNo}
                                    helperText={touched.mobileNo && errors.mobileNo}
                                />

                            </Stack>
                            <Stack direction="row" spacing={2} width="100%" marginBottom={2}>
                                <TextField
                                    fullWidth
                                    placeholder="Father ID"
                                    label="Father ID"
                                    required
                                    size="small"
                                    inputProps={{maxLength: 200, autoComplete: "off"}}
                                    value={values.fatherId}
                                    onChange={(e) =>
                                        setFieldValue("fatherId", e.target.value)
                                    }
                                    error={touched.fatherId && errors.fatherId}
                                    helperText={touched.fatherId && errors.fatherId}
                                />
                                <TextField
                                    fullWidth
                                    placeholder="Mother ID"
                                    label="Mother ID"
                                    required
                                    size="small"
                                    inputProps={{maxLength: 200, autoComplete: "off"}}
                                    value={values.motherId}
                                    onChange={(e) =>
                                        setFieldValue("motherId", e.target.value)
                                    }
                                    error={touched.motherId && errors.motherId}
                                    helperText={touched.motherId && errors.motherId}
                                />

                            </Stack>
                            <Stack direction="row" spacing={2} width="100%" marginBottom={2}>
                                <TextField
                                    select
                                    size="small"
                                    label={"Dzongkhag"}
                                    placeholder={"Dzongkhag"}
                                    fullWidth
                                    defaultValue=""
                                    value={values.dzongkhag}
                                    onChange={(event) => {
                                        handleDzongkhagChange(
                                            event,
                                            setFieldValue,
                                            setGewogsByDzongkhag,
                                            "dzongkhag",
                                            (errorMessage) => {
                                                console.log(`Custom Error Handler: ${errorMessage}`);
                                            }
                                        );
                                    }}
                                    helperText={
                                        touched.dzongkhag && errors.dzongkhag
                                    }
                                    error={
                                        touched.dzongkhag && errors.dzongkhag
                                    }
                                >
                                    {dzongkhags.map((item) => {
                                        return (
                                            <MenuItem
                                                key={`dzongkhag_${item.dzongkhagName}`}
                                                value={item.dzongkhagId}
                                            >
                                                {item.dzongkhagName}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                                <TextField
                                    select
                                    size="small"
                                    label={"Gewog"}
                                    placeholder={"Gewog"}
                                    fullWidth
                                    defaultValue=""
                                    value={values.gewog}
                                    onChange={(e) =>
                                        setFieldValue("gewog", e.target.value)
                                    }
                                    helperText={touched.gewog && errors.gewog}
                                    error={touched.gewog && errors.gewog}
                                >
                                    {gewogsByDzongkhag.map((item) => {
                                        return (
                                            <MenuItem
                                                key={`gewog_${item.geogName}`}
                                                value={item.geogId}
                                            >
                                                {item.geogName}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>

                            </Stack>
                            <Stack direction="row" spacing={2} width="100%" marginBottom={2}>
                                <TextField
                                    label={"Village"}
                                    placeholder={"Village"}
                                    fullWidth
                                    size="small"
                                    value={values.village}
                                    defaultValue=""
                                    onChange={(e) =>
                                        setFieldValue("village", e.target.value)
                                    }
                                    helperText={
                                        touched.village && errors.village
                                    }
                                    error={touched.village && errors.village}
                                />
                            </Stack>
                            <Box
                                width="100%"
                                display="flex"
                                sx={{justifyContent: "center"}}
                                marginTop={1}
                                spacing={1}
                                gap={1}
                            >
                                <Button
                                    style={{textTransform: "initial"}}
                                    variant="outlined"
                                    color="warning"
                                    onClick={() => {
                                        setShowAddNewDialog(false);
                                    }}
                                >
                                    <Typography>Close</Typography>
                                </Button>
                                <Button
                                    startIcon={<AddIcon/>}
                                    style={{textTransform: "initial"}}
                                    variant="outlined"
                                    color="info"
                                    disabled={loading}
                                    onClick={handleSubmit}
                                >
                                    <Typography>Add Now</Typography>
                                    {loading && (
                                        <CircularProgress
                                            size={30}
                                            sx={{
                                                position: "absolute",
                                                p: 1,
                                            }}
                                        />
                                    )}
                                </Button>
                            </Box>
                        </DialogContent>
                        <Collapse in={showAlert}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setShowAlert(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit"/>
                                    </IconButton>
                                }
                                severity={alertData.severity}
                            >{alertData.message}</Alert>
                        </Collapse>
                    </Dialog>


                    {/* Upload Excel dialog */}
                    <Dialog
                        TransitionComponent={Transition}
                        fullWidth={true}
                        open={showUploadExcelDialog}
                        onClose={handleUploadExcelDialog}
                        aria-labelledby="alert-dialog-title"
                    >
                        <DialogContent>
                            <Stack spacing={1}>
                                <Collapse in={openFileInstruction}>
                                    <Alert
                                        variant="outlined"
                                        severity="info"
                                        icon={false}
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenFileInstruction(false);
                                                }}
                                                sx={{
                                                    position: "absolute",
                                                    right: "8px",
                                                    top: "8px",
                                                    color: "#b36161",
                                                    borderRadius: "50%",
                                                    backgroundColor: "white",
                                                    border: "1px solid #b36161",
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit"/>
                                            </IconButton>
                                        }
                                        sx={{
                                            mb: 2,
                                            width: "100%",
                                            alignItems: "center",
                                            position: "relative",
                                        }}
                                    >
                                        <Typography gutterBottom variant="h6" fontWeight="bold">
                                            <InfoOutlinedIcon/> Instructions
                                        </Typography>
                                        <Typography align="justify">
                                            Please attach an Excel file. The file column name must
                                            contain <span style={{color: "red"}}>personalNo</span>,{" "}
                                            <span style={{color: "red"}}>name</span>,{" "}
                                            <span style={{color: "red"}}>dob</span>,{" "}
                                            <span style={{color: "red"}}>gender</span>,{" "}
                                            <span style={{color: "red"}}>dob</span>,{" "}
                                            <span style={{color: "red"}}>dzongkhag</span>,{" "}
                                            <span style={{color: "red"}}>gewog</span>,{" "}
                                            <span style={{color: "red"}}>village</span>,{" "}
                                            <span style={{color: "red"}}>fatherId</span>,{" "}
                                            <span style={{color: "red"}}>motherId</span>,{" "}and{" "}
                                            <span style={{color: "red"}}>mobileNo</span>. Please rename
                                            the column properly and upload.
                                        </Typography>
                                    </Alert>
                                </Collapse>

                                <Stack width="100%" display="flex">
                                    <Box
                                        padding={1}
                                        style={{
                                            borderRadius: "3px",
                                            textAlign: "center",
                                            border: "1px dashed #999",
                                            background: "#fff",
                                        }}
                                    >
                                        <input
                                            hidden
                                            type="file"
                                            onChange={handleFileChange}
                                            accept=".xlsx, .xls"
                                            id="fileInput"
                                            className="input-file"
                                        />
                                        <label
                                            htmlFor="fileInput"
                                            className="file-label"
                                            style={{cursor: "pointer"}}
                                        >
                                            <BackupOutlinedIcon style={{fontSize: 40}}/>
                                            <Typography>Choose an Excel file</Typography>
                                            {fileData.length > 0 && (
                                                <Typography
                                                    align="justify"
                                                    variant="body2"
                                                    color="error"
                                                >
                                                    The data in the table will be deleted when you upload
                                                    a new Excel file.
                                                </Typography>
                                            )}
                                        </label>
                                    </Box>
                                </Stack>
                            </Stack>
                            <Box
                                width="100%"
                                display="flex"
                                sx={{justifyContent: "center"}}
                                marginTop={1}
                            >
                                <Button
                                    style={{textTransform: "initial"}}
                                    variant="outlined"
                                    color="warning"
                                    onClick={() => {
                                        setShowUploadExcelDialog(false);
                                    }}
                                >
                                    <Typography>Close</Typography>
                                </Button>
                            </Box>
                        </DialogContent>
                    </Dialog>

                    {/* Notify Now Dialog */}
                    <Dialog
                        TransitionComponent={Transition}
                        fullWidth={true}
                        open={showConfirmDialog}
                        onClose={handleNotifyNowDialog}
                        aria-labelledby="alert-dialog-title"
                    >
                        <DialogContent>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {notifySuccess ? (
                                    <Stack
                                        width="100%"
                                        justifyContent="center"  // Centers vertically within the parent
                                        alignItems="center"      // Centers horizontally within the parent
                                        marginTop={1}
                                        display="flex"
                                    >
                                        <Alert icon={false} severity="success" variant="outlined"
                                        >
                                            <Typography
                                                textAlign="center"
                                                variant="h5"
                                                fontWeight="bold"
                                            >
                                                <TaskAltIcon style={{fontSize: 30}}/> Success
                                            </Typography>
                                            <Typography align="justify" variant="h6" gutterBottom>
                                                Bulk OGZ list upload successfully.
                                            </Typography>
                                            <Box display="flex" justifyContent="center">
                                                <Button
                                                    style={{textTransform: "initial"}}
                                                    size="small"
                                                    color="success"
                                                    variant="outlined"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    <Typography>Okay</Typography>
                                                </Button>
                                            </Box>
                                        </Alert>
                                    </Stack>
                                ) : (
                                    <>
                                        <Stack spacing={2}>
                                            <Stack width="100%" display="flex" marginBottom={2}>
                                                <Typography variant="h4" textAlign="center">
                                                    Confirmation
                                                </Typography>
                                            </Stack>
                                            <Stack width="100%" display="flex" marginBottom={1}>
                                                <Typography variant="h5" textAlign="center">
                                                    Are you sure you want to upload this OGZ List?
                                                </Typography>
                                            </Stack>
                                            <Box
                                                width="100%"
                                                display="flex"
                                                sx={{justifyContent: "center"}}
                                                marginTop={1}
                                                gap={1}
                                            >
                                                <Button
                                                    style={{textTransform: "initial"}}
                                                    variant="outlined"
                                                    color="warning"
                                                    disabled={loading}
                                                    onClick={() => {
                                                        setShowConfirmDialog(false);
                                                    }}
                                                >
                                                    <Typography>Cancel</Typography>
                                                </Button>
                                                <Button
                                                    style={{textTransform: "initial"}}
                                                    variant="outlined"
                                                    disabled={loading}
                                                    color="info"
                                                    onClick={(e) => {
                                                        sendBulkUpload();
                                                    }}
                                                >
                                                    <Typography>Confirm</Typography>
                                                    {loading && (
                                                        <CircularProgress
                                                            size={30}
                                                            sx={{
                                                                position: "absolute",
                                                                p: 1,
                                                            }}
                                                        />
                                                    )}
                                                </Button>
                                            </Box>
                                        </Stack>
                                    </>
                                )}
                                <Stack
                                    width="100%"

                                    marginTop={1}
                                    display="flex"
                                >
                                    <Collapse in={alertData.show}>
                                        <Alert
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setAlertData({...alertData, show: false});
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit"/>
                                                </IconButton>
                                            }
                                            severity={alertData.severity}
                                        >
                                            <Typography>{alertData.message}</Typography>
                                        </Alert>
                                    </Collapse>
                                </Stack>
                            </LocalizationProvider>
                        </DialogContent>
                    </Dialog>
                </>
            }
        />
    );
};

export default NewOGZList;
