import axios from "axios";
import authHeader from "./auth-header";
import multipartAuthHeader from "./auth-header-multipart";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class NsRegistrationService {
  nsRegistration(data) {
    return axios.post(
      BASE_URL +
        "api/enrolment/deferment/exemption/nsregistration/nsRegistration",
      data,
      { headers: multipartAuthHeader() }
    );
  }
  save(data) {
    return axios.post(
      BASE_URL + "api/enrolment/deferment/exemption/nsregistration",
      data,
      { headers: authHeader() }
    );
  }

  getRegisteredInfoByUserId(userId) {
    return axios.get(
      BASE_URL +
        "api/enrolment/deferment/exemption/nsregistration/getMyRegistrationInfo",
      // return axios.get(  "api/enrolment/deferment/exemption/enrolment/getMyEnrolmentInfo",
      { params: { userId }, headers: authHeader() }
    );
  }

  getRegistrationListByCriteria(
    enlistmentYear,
    status,
    gender,
    cid,
    graduateCategoryId,
    foodPreferenceId,
    highestQualificationCategoryId,
    currentQualificationCategoryId,
    highestQualificationYear,
    registrationFromDate,
    registrationToDate,
    offset,
    pageSize,
    dobYear,
    transportationMode,
    nonCid
  ) {
    return axios.get(
      BASE_URL +
        "api/enrolment/deferment/exemption/nsregistration/getRegistrationListByCriteria",
      {
        params: {
          enlistmentYear,
          status,
          gender,
          cid,
          graduateCategoryId,
          foodPreferenceId,
          highestQualificationCategoryId,
          currentQualificationCategoryId,
          highestQualificationYear,
          registrationFromDate,
          registrationToDate,
          offset,
          pageSize,
          dobYear,
          transportationMode,
          nonCid
        },
        headers: authHeader(),
      }
    );
  }

  checkUserAlreadyRegistered(userId) {
    return axios.get(
      BASE_URL +
        "api/enrolment/deferment/exemption/edeCommon/checkUserAlreadyRegistered",
      { params: { userId }, headers: authHeader() }
    );
  }

  getMyRegistrationInfo(userId,enlistmentYear) {
    return axios.get(
      BASE_URL +
        "api/enrolment/deferment/exemption/nsregistration/getMyRegistrationInfo",
      {
        params: { userId,enlistmentYear },
        headers: authHeader(),
      }
    );
  }

  //track applicant details by cid
  getApplicantDetail = (cid) => {
    return axios.get(
      BASE_URL + "api/enrolment/deferment/exemption/trackApplication/trackApplicantDetail",
      {
        params: { cid },
      }
    );
  };

  getProfileInfoByUserIds(userIds) {
    return axios.get(
        BASE_URL +
        "api/enrolment/deferment/exemption/nsregistration/getProfileInfoByUserIds",
        { params: { userIds }, headers: authHeader() }
    );
  }

  //track applicant details by cid
  trackApplicantDetailByPK = (personalKey) => {
    return axios.get(
        BASE_URL + "api/enrolment/deferment/exemption/trackApplication/trackApplicantDetailByPK",
        {
          params: { personalKey },
        }
    );
  };

  //track applicant details by cid
  generateLetterOfExemption = (cid,reportFormat) => {
    return axios.get(
        BASE_URL + "api/enrolment/deferment/exemption/trackApplication/generateLetterOfExemption",
        {
          params: { cid,reportFormat },
          responseType: 'blob'
        }
    );
  };

  //track applicant details by cid
  getUserAfterRegisInfo = (cid) => {
    return axios.get(
        BASE_URL + "api/enrolment/deferment/exemption/trackApplication/getUserAfterRegisInfo",
        {
          params: { cid },
        }
    );
  };


}

export default new NsRegistrationService();
