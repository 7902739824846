import dzongkhagService from "../../services/dzongkhag.service";

export const fetchDzongkhags = (setDzongkhags) => {
    dzongkhagService.getAll()
        .then((response) => {
            setDzongkhags(response.data);
        })
        .catch((error) => {
            console.error("Error fetching Dzongkhags:", error);
        });
};

export const handleDzongkhagChange = (event, setFieldValue, setGewogsCallback, fieldName, errorCallback) => {
    const dzongkhagId = event.target.value;
    setFieldValue(fieldName, dzongkhagId);

    // Fetch Gewog list after setting Dzongkhag value
    dzongkhagService.getGeogByDzongkhagId(dzongkhagId)
        .then((response) => {
            setGewogsCallback(response.data);
        })
        .catch((error) => {
            const errorMessage =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();

            console.error("Error fetching Gewog list:", errorMessage);

            // Use the provided errorCallback or default to logging the error
            if (errorCallback) {
                errorCallback(errorMessage);
            } else {
                alert(`Failed to load Gewog list: ${errorMessage}`);
            }
        });
};