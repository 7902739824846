import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import SignUpInstruction from "./SignUpInstruction";
import SignUpPersonalDetail from "./SignUpPersonalDetail";
import SignUpContactDetail from "./SignUpContactDetail";
import SignUpVerification from "./SignUpVerification";
import SignupSuccess from "./SignupSuccess";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Fade from "@mui/material/Fade";
import { useDispatch } from "react-redux";
import { register } from "../../actions/auth";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import PageTransitionFadeIn from "../animation/PageAnimation/PageAnimation";
import WebsiteHeader from "../Home/WebsiteHeader";
import { useFormik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileView = useMediaQuery("(max-width:600px)");
  const [activeStep, setActiveStep] = useState(0);
  const [showBackBtn, setShowBackBtn] = useState(false);
  const [alertData, setAlertData] = useState({ severity: "info", message: "" });
  const [showAlert, setShowAlert] = useState(false);
  const [progress, setProgress] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [personalData, setPersonalData] = useState({
    fullName: "",
    gender: "",
    cid: "",
    dob: "",
    fatherName: "",
    fatherCid: "",
    motherName: "",
    motherCid: "",
    villageName: "",
    geogName: "",
    dzongkhagName: "",
    userWhiteList:false
  });
  const [contactDetail, setContactDetail] = useState({
    presentCountry: "",
    mobileNo: "",
    email: "",
    password: "",
    confirmPassword: "",
    verificationCode: "",
    otp: "",
  });

  const reqErrorMsg = <span>{t("This field is required")}</span>;
  const pwLengthErrorMsg = (
    <span>
      {t(
        "It should be at least 8 characters long but less than 30 characters."
      )}
    </span>
  );
  const pwPolicyErrorMsg = (
    <span>
      {t(
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      )}
    </span>
  );
  const confirmPwErrorMsg = <span>{t("Confirm password did match")}</span>;
  const invalidEmailErrorMsg = <span>{t("Invalid email")}</span>;

  const contactDetailFormik = useFormik({
    initialValues: {
      presentCountry: "Bhutan",
      countryCode: "",
      phoneOrEmailVerification: "",
      mobileNo: "",
      otp: "",
      email: "",
      verificationCode: "",
      mobileNoVerified: false,
      emailVerified: false,
      password: "",
      confirmPassword: "",
      pwVerified: false,
      helperRequired: null,
      helperName: "",
      helperMobileNo: "",
      helperEmail: "",
      helperRelation: ""
    },
    onSubmit: () => {},
    // validationSchema: contactDetailValidationSchema,
    validationSchema: Yup.object().shape({
      mobileNo: Yup.number().required(reqErrorMsg),
      email: Yup.string().email(invalidEmailErrorMsg).required(reqErrorMsg),
      password: Yup.string()
        .min(8, pwLengthErrorMsg)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()`*+,-./\\:;<=>?@[\]^_{|}~])[A-Za-z\d!"#$%`&'()*+,-./\\:;<=>?@[\]^_{|}~]+$/,
          pwPolicyErrorMsg
        )
        .required(reqErrorMsg),
      confirmPassword: Yup.string()
        .required(reqErrorMsg)
        .oneOf([Yup.ref("password"), null], confirmPwErrorMsg),
    }),
  });

  const helperFormik = useFormik({
    initialValues: {
      helperRequired: null,
      helperName: "",
      helperMobileNo: "",
      helperEmail: "",
      helperRelation: "",
    },
    onSubmit: () => {},
    validationSchema: Yup.object().shape({
      helperName: Yup.string().required(reqErrorMsg),
      helperMobileNo: Yup.number().required(reqErrorMsg),
      helperEmail: Yup.string().required(reqErrorMsg),
      helperRelation: Yup.string().required(reqErrorMsg),
    }),
  });

  const steps = [
    <Typography>{t("Instructions")}</Typography>,
    <Typography>{t("Personal details")}</Typography>,
    <Typography>{t("Contact details")}</Typography>,
    <Typography>{t("Verification")}</Typography>,
  ];

  const [isChecked, setIsChecked] = useState(false);

  const [disableNextBtn, setDisableNextBtn] = useState(true);

  const onDeclarationChecked = (checked) => {
    setIsChecked(checked);
  };

  useEffect(() => {
    if (activeStep === 0) {
      setShowBackBtn(false);
    } else if (activeStep === 1) {
      setShowBackBtn(true);
      if (personalData.fullName !== "") {
        setDisableNextBtn(false);
      } else {
        setDisableNextBtn(true);
      }
    } else if (activeStep === 2) {
      setShowBackBtn(true);
      if (
        (contactDetailFormik.values.mobileNoVerified ||
          contactDetailFormik.values.emailVerified) &&
        !Boolean(contactDetailFormik.errors.password) &&
        !Boolean(contactDetailFormik.errors.confirmPassword)
        //todo: password verified condition
        // contactDetailFormik.values.pwVerified
      ) {
        setDisableNextBtn(false);
      } else {
        setDisableNextBtn(true);
      }
    } else if (activeStep === 3) {
      setShowBackBtn(true);
      contactDetailFormik.handleSubmit();
    }
  }, [activeStep, contactDetailFormik.errors.confirmPassword]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    if (activeStep <= 1) setShowBackBtn(false);
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    setProgress(true);
    const data = {};
    for (const key in personalData) {
      if (
        ![
          "houseNo",
          "thramNo",
          "genderName",
        ].includes(key)
      ) {
        switch (key) {
          case "villageName":
            data["permanentPlaceName"] = personalData[key];
            break;
          case "geogName":
            data["permanentGeog"] = personalData[key];
            break;
          case "dzongkhagName":
            data["permanentDzongkhag"] = personalData[key];
            break;
          case "dob":
            data["birthDate"] = personalData[key];
            break;
          case "gender":
            data["gender"] =
              personalData[key] === "Male"
                ? "M"
                : personalData[key] === "Female"
                ? "F"
                : personalData[key];
            break;
          case "presentCountry":
            data["presentCountry"] = personalData[key];
            break;
          case "houseNo":
            break;
          case "thramNo":
            break;
          default:
            data[key] = personalData[key];
        }
      }
    }

    for (const key in contactDetailFormik.values) {
      if (
        ![
          "mobileNoVerified",
          "emailVerified",
          "countryCode",
          "pwVerified",
        ].includes(key)
      ) {
        data[key] = contactDetailFormik.values[key];
      }
    }

    for (const key in helperFormik.values) {
      data[key] = helperFormik.values[key];
    }

    dispatch(register(data)).then(
      (response) => {
        setResponseData(response.data);
        setAlertData({ severity: "success", message: "Registration success!" });
        setActiveStep((prevActiveStep) => {
          return prevActiveStep + 1;
        });
      },
      (error) => {
        setAlertData({
          severity: "error",
          message: error.response.data.message,
        });
        setShowAlert(true);
      }
    );
  };

  const generateStepContent = () => {
    switch (activeStep) {
      case 0:
        return <SignUpInstruction setDisableNextBtn={setDisableNextBtn} />;
      case 1:
        return (
          <SignUpPersonalDetail
            personalData={personalData}
            setPersonalData={setPersonalData}
            setDisableNextBtn={setDisableNextBtn}
          />
        );
      case 2:
        return (
          <SignUpContactDetail
            personalData={personalData}
            contactDetail={contactDetail}
            setContactDetail={setContactDetail}
            setDisableNextBtn={setDisableNextBtn}
            contactDetailFormik={contactDetailFormik}
            helperFormik={helperFormik}
          />
        );
      case 3:
        return (
          <SignUpVerification
            personalData={personalData}
            contactDetail={contactDetail}
            onDeclarationChecked={onDeclarationChecked}
            contactDetailFormik={contactDetailFormik}
            isChecked={isChecked}
          />
        );
      default:
        return;
    }
  };

  const nextButton = (
    <Button
      style={{ textTransform: "initial" }}
      disabled={disableNextBtn}
      onClick={handleNext}
      variant="outlined"
      endIcon={<ArrowForwardIcon />}
    >
      <Typography>{t("Next")}</Typography>
    </Button>
  );

  const submitButton = (
    <Button
      style={{ textTransform: "initial" }}
      disabled={!isChecked || progress}
      onClick={handleSubmit}
      variant="outlined"
    >
      <Typography>{t("Submit")}</Typography>
      {progress && (
        <CircularProgress
          size={30}
          sx={{
            position: "absolute",
            p: 1,
          }}
        />
      )}
    </Button>
  );

  return (
    <PageTransitionFadeIn
      child={
        <Box maxHeight={"auto"}>
          {/*pass true, inside website header it will be inverted*/}
          <WebsiteHeader
            initialY={"-10vh"}
            animateY={"0vh"}
            showSignUp={true}
          />
          {isMobileView ? <Box height={15} /> : <Toolbar />}
          <Container>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  // boxShadow: 3,
                }}
              >
                {activeStep === steps.length ? (
                  <Box>
                    <Toolbar />
                    <SignupSuccess data={responseData} />
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    height={"auto"}
                    gap={1}
                    ml={5}
                    mr={5}
                    justifyContent="space-between"
                  >
                    <Box>
                      <Toolbar />
                      <Box display={{ xs: "none", sm: "block" }}>
                        <Stepper activeStep={activeStep}>
                          {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                              <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                              </Step>
                            );
                          })}
                        </Stepper>
                        <br />
                      </Box>
                    </Box>
                    {generateStepContent()}
                    {/*next & back button*/}
                    <Stack>
                      <hr />
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        justifyContent="right"
                        // my={3}
                      >
                        {/*back button*/}
                        <Fade in={showBackBtn}>
                          <Button
                            disabled={progress}
                            onClick={handleBack}
                            variant="outlined"
                            style={{ textTransform: "initial" }}
                            startIcon={<ArrowBackIcon />}
                          >
                            <Typography>{t("Back")}</Typography>
                          </Button>
                        </Fade>
                        <br />
                        {/*next or submit button*/}
                        {activeStep === 3 ? submitButton : nextButton}
                      </Box>
                    </Stack>
                    {/* alert message */}
                    <Collapse in={showAlert}>
                      <Alert
                        variant="outlined"
                        severity={alertData.severity}
                        action={
                          <IconButton onClick={() => setShowAlert(false)}>
                            <CloseIcon />
                          </IconButton>
                        }
                      >
                        <Typography>{alertData.message}</Typography>
                      </Alert>
                    </Collapse>
                  </Box>
                )}
              </Box>
            </Box>
          </Container>
        </Box>
      }
    />
  );
};

export default SignUp;
