import * as Yup from 'yup';

const OGZListSchema = Yup.object().shape({
    personalNo: Yup.string().required('Personal Number is required field'),
    name: Yup.string().required('Full Name is required field'),
    dob: Yup.string().required('DOB is required field'),
    gender: Yup.string().required('Gender is required field'),
    mobileNo: Yup.string().required('Mobile Number is required field'),
    fatherId: Yup.string().required('Father ID is required field'),
    motherId: Yup.string().required('Mother ID is required field'),
    dzongkhag: Yup.string().required('Dzongkhag is required field'),
    gewog: Yup.string().required('Gewog is required field'),
    village: Yup.string().required('Village is required field'),
});

export default OGZListSchema;