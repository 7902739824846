import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {motion} from "framer-motion";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";

import {
    Alert,
    Box,
    CircularProgress,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import loginBgFinal from "../../assets/background/dsktop-FINAL.jpg";
import loginBgMobile from "../../assets/background/mobile-02.jpg";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import {useFormik} from "formik";
import * as Yup from "yup";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PageTransitionFadeIn from "../animation/PageAnimation/PageAnimation";
import AuthServices from "../../services/auth.services";
import NdiServices from "../../services/ndi.service";
import {sideMenuChildrenSetter} from "../../redux/sideMenuItemSetter";
import NDIlogobg from "../../assets/ndi/NDIlogobg.png";
import LoginNDIScanCode from "../../NDI/LoginNDIScanCode";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import {useTranslation} from "react-i18next";
import Switch from "@mui/material/Switch";

const SignIn = () => {
    const [lang, setLang] = useState(false);
    const {t, i18n} = useTranslation();
    let navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState({severity: "info", message: ""});
    const [showPassword, setShowPassword] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const [url, setUrl] = useState("");
    const [deepLinkUrl, setDeepLinkUrl] = useState("");

    const [ndiScanOpen, setNdiScanOpen] = useState(false);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const reqErrorMsg = <span>{t("This field is required")}</span>;

    const {values, handleSubmit, setFieldValue, touched, errors} = useFormik({
        initialValues: {
            username: "",
            password: "",
            isNDILogin: false,
        },
        onSubmit: () => {
            handleLogin(values.username, values.password, values.isNDILogin);
        },
        validationSchema: Yup.object().shape({
            username: Yup.string().required(reqErrorMsg),
            password: Yup.string().required(reqErrorMsg),
        }),
    });

    // function onLangChange(event) {
    //     setLang(event.target.checked);
    //     if (event.target.checked) {
    //         i18n.changeLanguage("dz");
    //         localStorage.setItem("language", "DZO");
    //     } else {
    //         i18n.changeLanguage("en");
    //         localStorage.setItem("language", "ENG");
    //     }
    // }

    const dispatch = useDispatch();
    const mobileScreen = useMediaQuery("(max-width:600px)");
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [progressNDI, setProgressNDI] = useState(true);

    // const isAuthenticated = useAuth();

    //To call ndi proof verification
    const proofNdiRequest = () => {
        NdiServices.proofNdiRequest().then((res) => {
            setUrl(res.data.inviteURL);
            setDeepLinkUrl(res.data.deepLinkURL);
            setProgressNDI(false);
            natsListener(res.data.threadId, res.data.accessToken);
        });
    };

    const handleLogin = (username, password, isNDILogin) => {
        setIsInProgress(true);
        AuthServices.login(username, password, isNDILogin).then(
            (response) => {
                if (response.name) {
                    let msg = "";
                    setIsInProgress(false);
                    if (response.response.data.message === undefined) {
                        msg = response.response.data.errors;
                    } else {
                        if (response.response.data.message) {
                            msg = response.response.data.message;
                        } else if (response.message) {
                            msg = response.message;
                        }
                    }
                    setAlertData({
                        severity: "warning",
                        // message: msg,
                        message: "CID or password is incorrect.",
                    });
                    setShowAlert(true);
                } else {
                    dispatch(sideMenuChildrenSetter(response.accessScreens));
                    const userType = response.roles.filter((item) => {
                        return item.userType === "S";
                    });

                    if (userType.length > 0) {
                        navigate("/authenticatedViewWrapper/openUserDashboard"); //if user is applicant, by default navigate to applicant profile
                    } else {
                        navigate("/authenticatedViewWrapper/dashboard"); //if user is non-applicant, by default navigate to dashboard
                    }
                    setIsInProgress(false);
                }
            },
            (error) => {
                setShowAlert(true);
                // let msg = "";
                setIsInProgress(false);
                // msg = error.response.data.message;
                setAlertData({
                    severity: "error",
                    message: "CID or password is incorrect.",
                    // message: error.response.data.message,
                });
            }
        );
    };

    function natsListener(threadId) {
                let endPoint =
                    BASE_URL + "api/auth/ndi/subscribe?threadId=" + threadId;
                let eventSource = new EventSource(endPoint);
                eventSource.addEventListener("NDI_SSI_EVENT", (event) => {
                    let data = JSON.parse(event.data);
                    if (data.status === "exists") {
                        handleLogin(data.userDTO.cidNumber, "12348579", true);
                    } else {
                        setShowAlert(true);
                        setNdiScanOpen(false);
                        setAlertData({
                            severity: "warning",
                            message: data.userDTO.message,
                        });

                    }
        });
    }

    // function onLangChange(event) {
    //     setLang(event.target.checked)
    //     if(event.target.checked){
    //         i18n.changeLanguage('dz')
    //     } else {
    //         i18n.changeLanguage('en')
    //     }
    // }



    return (
        <PageTransitionFadeIn
            child={
                // image background
                <Box
                    display="flex"
                    justifyContent="center"
                    height="100vh"
                    alignItems="center"
                    sx={{
                        backgroundImage: isMobile
                            ? `url(${loginBgMobile})`
                            : `url(${loginBgFinal})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        position: "relative",
                    }}
                >
                    <motion.div
                        initial={{scale: 0}}
                        animate={{scale: 1}}
                        transition={{delay: 0.3, duration: 0.8, type: "spring"}}
                        style={{
                            maxHeight: "inherit",
                            // width: mobileScreen ? "1200%" : "30%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        {/*  login form*/}
                        <Box
                            flex="100%"
                            boxShadow={3}
                            flexDirection="column"
                            mx={mobileScreen ? 1 : 0}
                            maxHeight="inherit"
                            sx={{
                                alignItems: "center",
                                display: "flex",
                                height: "fit-content",
                                background: "white",
                                borderRadius: "4px",
                                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                                backdropFilter: "blur(5px)",
                                "*::WebkitBackdropFilter": "blur(5px)",
                                border: "1px solid rgba(255, 255, 255, 1)",
                            }}
                        >
                            {/* language change */}
              {/*               <Box marginTop={1}>*/}

              {/*  <Typography>*/}
              {/*    English*/}
              {/*    <Switch*/}
              {/*      checked={lang}*/}
              {/*      onChange={onLangChange}*/}
              {/*      color="secondary"*/}
              {/*    />*/}
              {/*    རྫོང་ཁ།*/}
              {/*  </Typography>*/}
              {/*</Box>*/}
                            <Typography
                                align="center"
                                pt={2}
                                variant="h5"
                                style={{textAlign: "center"}}
                            >
                                {t("Please login to continue")}
                            </Typography>

                            <Box
                                p={{xs: 2, sm: 5}}
                                display="flex"
                                flexDirection="column"
                                gap={2}
                            >
                                {/*userName*/}
                                <TextField
                                    disabled={isInProgress}
                                    fullWidth
                                    placeholder={t("CID")}
                                    label={t("CID")}
                                    required
                                    size="small"
                                    value={values.username}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => setFieldValue("username", e.target.value.trim())}
                                    error={touched.username && errors.username}
                                    helperText={touched.username && errors.username}
                                />
                                {/*password*/}
                                <TextField
                                    disabled={isInProgress}
                                    fullWidth
                                    placeholder={t("Password")}
                                    label={t("Password")}
                                    required
                                    size="small"
                                    type={!showPassword ? "password" : undefined}
                                    minLength={8}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon/>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position={"end"}>
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOffIcon/>
                                                    ) : (
                                                        <VisibilityIcon/>
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => setFieldValue("password", e.target.value)}
                                    error={touched.password && errors.password}
                                    helperText={touched.password && errors.password}
                                />
                                <Box
                                    display="flex"
                                    flexDirection={{xs: "column", md: "row"}}
                                    gap={1}
                                    justifyContent={{xs: "flex-end", md: "space-between"}}
                                >
                                    <Button
                                        style={{textTransform: "initial"}}
                                        disabled={isInProgress}
                                        variant="outlined"
                                        onClick={() => handleSubmit()}
                                    >
                                        <Typography>{t("Login")}</Typography>
                                        {isInProgress && (
                                            <CircularProgress
                                                sx={{
                                                    position: "absolute",
                                                    p: 1,
                                                }}
                                            />
                                        )}
                                    </Button>
                                    <Typography
                                        variant="body2"
                                        sx={{textAlign: {xs: "right", md: "left"}}}
                                    >
                                        <Link
                                            component="button"
                                            disabled={isInProgress}
                                            onClick={(e) => navigate("/forgotPassword")}
                                        >
                                            {t("Forgot Password?")}
                                        </Link>
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection={{xs: "column", md: "row"}}
                                    gap={1}
                                    justifyContent={{xs: "flex-end", md: "space-between"}}
                                >
                                    <Button
                                        fullWidth
                                        size="small"
                                        onClick={(e) => {
                                            setUrl("");
                                            setProgressNDI(true);
                                            setDeepLinkUrl("");
                                            proofNdiRequest();
                                            setNdiScanOpen(true);
                                        }}
                                        style={{
                                            backgroundColor: `#124143`,
                                            textTransform: "initial",
                                        }}
                                    >
                                        <Typography sx={{color: "#F0F9F4"}}>
                                            <img
                                                src={NDIlogobg}
                                                width="30"
                                                height="30"
                                                alt="NDI Logo"
                                                style={{marginRight: "10px"}}
                                                progressNDI={progressNDI}
                                            />
                                            Login with Bhutan NDI
                                        </Typography>
                                    </Button>
                                </Box>

                                <Box justifyContent="space-between">
                                    <Typography variant="body2" align="center">
                                        {t("Don't have an account yet?")}
                                    </Typography>
                                </Box>
                                <Stack direction="row" justifyContent="center">
                                    <Button
                                        startIcon={<PersonAddAltOutlinedIcon/>}
                                        style={{textTransform: "initial"}}
                                        variant="outlined"
                                        disabled={isInProgress}
                                        onClick={(e) => navigate("/signup")}
                                        sx={{width: "100%"}}
                                    >
                                        <Typography>{t("Sign up")}</Typography>
                                    </Button>
                                </Stack>
                                <Stack direction="row" justifyContent="center">
                                    <Button
                                        disabled={isInProgress}
                                        style={{textTransform: "initial"}}
                                        color="info"
                                        onClick={(e) => navigate("/trackApplication")}
                                        variant="outlined"
                                        sx={{width: "100%"}}
                                    >
                                        <SearchIcon/>
                                        <Typography>{t("Track Here")}</Typography>
                                    </Button>
                                </Stack>
                            </Box>
                            {/*bottom corners*/}
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems={"end"}
                            >
                                <Collapse in={showAlert}>
                                    <Alert
                                        sx={{mb: 2}}
                                        severity={alertData.severity}
                                        variant="outlined"
                                        action={
                                            <IconButton
                                                color="inherit"
                                                size="small"
                                                onClick={() => setShowAlert(false)}
                                            >
                                                <CloseIcon/>
                                            </IconButton>
                                        }
                                    >
                                        <Typography align="justify">{alertData.message}</Typography>
                                    </Alert>
                                </Collapse>
                            </Stack>
                        </Box>
                        <br/>

                        <LoginNDIScanCode
                            url={url}
                            ndiScanOpen={ndiScanOpen}
                            setNdiScanOpen={setNdiScanOpen}
                            isMobile={isMobile}
                            deepLinkUrl={deepLinkUrl}
                            progressNDI={progressNDI}
                        />
                    </motion.div>
                </Box>
            }
        />
    );
};

export default SignIn;
