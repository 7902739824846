import axios from "axios";
import authHeader from "./auth-header";
import multipartAuthHeader from "./auth-header-multipart";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class EarlyEnlistment {
  requestGuardianConsent(data) {
    return axios.post(
      BASE_URL +
        "api/enrolment/deferment/exemption/earlyEnlistment/requestGuardianConsent",
      data,
      { headers: authHeader() }
    );
  }

  checkAgeValidation(data) {
    return axios.post(
      BASE_URL +
        "api/enrolment/deferment/exemption/earlyEnlistment/checkAgeValidation",
      data,
      { headers: authHeader() }
    );
  }
  getGuardianConsentStatus(enlistmentYear) {
    return axios.get(
      BASE_URL +
        "api/enrolment/deferment/exemption/earlyEnlistment/getGuardianConsentStatus",
        {   params: { enlistmentYear },headers: authHeader() }
    );
  }

  getEarlyEnlistmentStatus() {
    return axios.get(
      BASE_URL +
        "api/enrolment/deferment/exemption/earlyEnlistment/getEarlyEnlistmentStatus",
      { headers: authHeader() }
    );
  }

  applyEarlyEnlistment(data) {
    return axios.post(
      BASE_URL +
        "api/enrolment/deferment/exemption/earlyEnlistment/applyEarlyEnlistment",
      data,
      { headers: multipartAuthHeader() }
    );
  }

  preEnlistmentApply(data) {
    return axios.post(
        BASE_URL +
        "api/enrolment/deferment/exemption/earlyEnlistment/preEnlistmentApply",
        data,
        { headers: multipartAuthHeader() }
    );
  }

  getPreEarlyEnlistmentByUserId(userId) {
    return axios.get(
        BASE_URL +
        "api/enrolment/deferment/exemption/earlyEnlistment/getPreEarlyEnlistmentByUserId",
        {   params: { userId },headers: authHeader() }
    );
  }

  getEarlyEnlistmentListByCriteria(
    enlistmentYear,
    status,
    gender,
    cid,
    parentConsentStatus,
    dzongkhagId,
    offset,
    pageSize,
    dobYear,
    nonCid
  ) {
    return axios.get(
      BASE_URL +
        "api/enrolment/deferment/exemption/earlyEnlistment/getEarlyEnlistmentListByCriteria",
      {
        params: {
          enlistmentYear,
          status,
          gender,
          cid,
          parentConsentStatus,
          dzongkhagId,
          offset,
          pageSize,
          dobYear,
          nonCid
        },
        headers: authHeader(),
      }
    );
  }

  checkAgeValidationByUser(data) {
    return axios.post(
        BASE_URL +
        "api/enrolment/deferment/exemption/earlyEnlistment/checkAgeValidationByUser",
        data,
        { headers: authHeader() }
    );
  }

  approveById(
               enlistmentId,
               remarks,
               userId,
               status,
               emailContent) {
    return axios.post(
      BASE_URL +
        "api/enrolment/deferment/exemption/earlyEnlistment/approveById",
        {
          enlistmentId,
          remarks,
          userId,
          status,
          emailContent,
        },
      {
        headers: authHeader(),
      }
    );
  }

  updateStatusEarlyEnlistment (
      data
  ){
    return axios.post(
        BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/updateStatusEarlyEnlistment",
        data,
        {
          headers: authHeader(),
        }
    );
  };
  getEarlyEnlistmentFilesByEnlistmentId(enlistmentId) {
    return axios.get(
        BASE_URL +
        "api/enrolment/deferment/exemption/earlyEnlistment/getEarlyEnlistmentFilesByEnlistmentId",
        {   params: { enlistmentId },headers: authHeader() }
    );
  }

  getEarlyEnlistmentListByUserId(userId) {
    return axios.get(BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/getEarlyEnlistmentListByUserId", {
      params: { userId },
      headers: authHeader(),
    });
  }

  getEarlyEnlistmentByEnlistmentId(enlistmentId) {
    return axios.get(BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/getEarlyEnlistmentByEnlistmentId", {
      params: { enlistmentId },
      headers: authHeader(),
    });
  }

  deleteFileByEnlistmentId(data) {
    return axios.post(BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/deleteFileByEnlistmentId", data, {
      headers: authHeader(),
    });
  };

  getAllEarlyEnlistmentActivity = (enlistmentId) => {
    return axios.get(BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/getEarlyEnlistmentAuditListById", {
      params: { enlistmentId },
      headers: authHeader(),
    });
  };

  getEmailContentByEnlistmentId = (enlistmentId) => {
    return axios.get(
        BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/getEmailContentByEnlistmentId",
        { params: { enlistmentId }, headers: authHeader() }
    );
  };

  revertById = (
      enlistmentId,
      reviewRemarks,
      userId,
      status,
      emailContent
  ) => {
    return axios.post(
        BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/revertById",
        {
          enlistmentId,
          reviewRemarks,
          userId,
          status,
          emailContent,
        },
        {
          headers: authHeader(),
        }
    );
  };

  cancelById = (data) => {
    return axios.post(BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/cancelById", data, {
      headers: authHeader(),
    });
  };

  getPreLatestEarlyEnlistmentByUserId = (userId,enlistmentYear) => {
    return axios.get(BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/getPreLatestEarlyEnlistmentByUserId", {
      params: { userId, enlistmentYear },
      headers: authHeader(),
    });
  };

  getEnlistmentAuditFileByAuditId = (enlistmentAuditId) => {
    return axios.get(BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/getEnlistmentAuditFileByAuditId", {
      params: { enlistmentAuditId },
      headers: authHeader(),
    });
  };

  getEarlyEnlistmentListByUserIdAndStatus = (userId) => {
    return axios.get(BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/getEarlyEnlistmentListByUserIdAndStatus", {
      params: { userId },
      headers: authHeader(),
    });
  };
  getEmailContentByEnlistmentAuditId = (enlistmentAuditId) => {
    return axios.get(
        BASE_URL + "api/enrolment/deferment/exemption/earlyEnlistment/getEmailContentByEnlistmentAuditId",
        { params: { enlistmentAuditId }, headers: authHeader() }
    );
  };


}

export default new EarlyEnlistment();
