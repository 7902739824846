import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import { keyframes } from '@emotion/react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {Box, Stack, Typography, CircularProgress, Chip, Dialog, DialogActions} from "@mui/material";
import WebsiteHeader from "../Home/WebsiteHeader";
import nsEnrollment from "../../services/ns.registration.service";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {useTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import GyalsungLogo from "../../assets/logo/Desuung-Logo.jpg";
const blink = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
`;
const TrackApplicationDemo = () => {
    const {t, i18n} = useTranslation();
    const [showImageDialog, setShowImageDialog] = useState(false);
    const [photoUrl, setPhotoUrl] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let language = localStorage.getItem("language");

        if (language === "ENG") {
            i18n.changeLanguage("en");
        } else {
            i18n.changeLanguage("dz");
        }
    }, []);

    return (
        <>
            <Box bgcolor="white" position={"relative"}>
                <WebsiteHeader initialY={"-10vh"} animateY={"0vh"}/>
                <Box
                    display="flex"
                    justifyContent="center"
                    height="100vh"
                    alignItems="center"
                    position={"relative"}
                >
                    <Box
                        boxShadow={3}
                        borderRadius={2}
                        display="flex"
                        flexDirection="column"
                        width={{md: "60%", sx: "100%"}}
                        sx={{
                            background: "rgba(255, 255, 255, 0.77)",
                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                            backdropFilter: "blur(5px)",
                            "*::WebkitBackdropFilter": "blur(5px)",
                            border: "1px solid rgba(255, 255, 255, 1)",
                        }}
                    >
                        <Box
                            p={{xs: 5, sm: 5}}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <>
                                <Stack spacing={1}>
                                    <Stack
                                        spacing={6}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        spacing={6}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        spacing={6}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                            {/*<img*/}
                                            {/*    src={GyalsungLogo}*/}
                                            {/*    width="200"*/}
                                            {/*    height="150"*/}
                                            {/*    alt="NDI Logo"*/}
                                            {/*    style={{marginRight: "10px"}}*/}
                                            {/*/>*/}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                            {/*<img*/}
                                            {/*    src={GyalsungLogo}*/}
                                            {/*    width="200"*/}
                                            {/*    height="150"*/}
                                            {/*    alt="NDI Logo"*/}
                                            {/*    style={{marginRight: "10px"}}*/}
                                            {/*/>*/}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                            {/*<img*/}
                                            {/*    src={GyalsungLogo}*/}
                                            {/*    width="200"*/}
                                            {/*    height="150"*/}
                                            {/*    alt="NDI Logo"*/}
                                            {/*    style={{marginRight: "10px"}}*/}
                                            {/*/>*/}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                            {/*<img*/}
                                            {/*    src={GyalsungLogo}*/}
                                            {/*    width="200"*/}
                                            {/*    height="150"*/}
                                            {/*    alt="NDI Logo"*/}
                                            {/*    style={{marginRight: "10px"}}*/}
                                            {/*/>*/}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                            {/*<img*/}
                                            {/*    src={GyalsungLogo}*/}
                                            {/*    width="200"*/}
                                            {/*    height="150"*/}
                                            {/*    alt="NDI Logo"*/}
                                            {/*    style={{marginRight: "10px"}}*/}
                                            {/*/>*/}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                            <img
                                                src={GyalsungLogo}
                                                width="200"
                                                height="150"
                                                alt="NDI Logo"
                                                style={{marginRight: "10px"}}
                                            />
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        direction={{xs: "column", sm: "row"}}
                                        spacing={3}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gap={2}
                                        >

                                            <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow>
                                                            {/*/!*<TableCell>*!/*/}
                                                            {/*    <img*/}
                                                            {/*        src={GyalsungLogo}*/}
                                                            {/*        width="30"*/}
                                                            {/*        height="30"*/}
                                                            {/*        alt="NDI Logo"*/}
                                                            {/*        style={{marginRight: "10px"}}*/}
                                                            {/*    />*/}
                                                            {/*/!*</TableCell>*!/*/}
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                <Typography>{t("CID")}: 1100800XXXX</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                <Typography>{t("Personal Number")}: 24K0001</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                <Typography>{t("Name")}: Chuki Wangmo</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                <Typography>{t("Batch")}: 2024</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                        <Typography>{t("Sign up")}: Signed up on December 12, 2023, 04:50 PM</Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell align="left">
                                                                <Typography>{t("NS Registration")}: Registered on December 12, 2023, 05:04 PM</Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell align="left">
                                                                <Typography>
                                                                    {t("Early Enlistment")}: N/A
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell align="left">
                                                                <Typography>{t("Deferment")}: N/A</Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell align="left">
                                                                <Typography>{t("Exemption")}:N/A</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                        <Typography>{t("Training Duration")}: September 5, 2024 To December 03 2024</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                           <Typography>{t("Academy")}: Gyalpozhing</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* preview image dialog */}
            <Dialog
                open={showImageDialog}
                aria-labelledby="Image Dialog"
                aria-describedby="Image Dialog"
                fullWidth={true}
            >
                <DialogContent>
                    {photoUrl ? (
                        <iframe
                            title="PDF Viewer"
                            src={photoUrl}
                            style={{
                                width: isMobile ? '100%' : '600px',
                                height: isMobile ? '820px' : '850px',
                                border: '0px solid #e0e0e0',
                                boxShadow: '0 2px 8px',
                                zoom: isMobile ? '0.49' : '0.89',
                            }}
                        />
                    ) : (
                        <p>Loading PDF...</p>
                    )}
                </DialogContent>

                <DialogActions
                    sx={{
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >

                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ textTransform: "initial" }}
                        onClick={(e) => setShowImageDialog(false)}
                    >
                        <Typography>Close</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TrackApplicationDemo;
