import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL =
    process.env.REACT_APP_BASE_URL + "api/user/profile/ogz_list";

const bulkUploadOGZList = (data) => {
    return axios.post(BASE_URL + "/uploadOGZList", data, {
        headers: authHeader(),
    });
};

const searchOGZList = (gender, personalId, offset, pageSize) => {
    return axios.get(BASE_URL + "/searchOGZList", {
        params: {gender, personalId, offset, pageSize,},
        headers: authHeader(),
    });
};

const save = (formData) => {
    return axios.post(BASE_URL+"/addOGZList", formData,
        {
            headers: authHeader()
        }
    );
};

export default {
    bulkUploadOGZList,
    searchOGZList,
    save
};
