import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, tokens, useMode } from "./theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as React from "react";
import SignUp from "./components/SignUp/SignUp";
import SignIn from "./components/SignIn/SignIn";
import AuthenticatedViewWrapper from "./components/AuthenticatedViewWrapper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import ForgotPassword from "./components/ResetUserPassword/ForgotPassword";
import ResetPassword from "./components/ResetUserPassword/ResetPassword";
import GuardianConsent from "./components/GuardianConsent/GuardianConsent";
import NDISignUp from "./components/SignUp/NDISignUp";
import TrackApplication from "./components/UserProfile/TrackApplication";
import TrackApplicationDemo from "./components/UserProfile/TrackApplicationDemo";
import CertificateDetails from "./components/UserProfile/CertificateDetails";
import LetterOfExemption from "./components/Exemption/LetterOfExemption";

import SignInNdi from "./components/SignIn/SignInNdi";
function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          {/* <SessionChecker /> */}
          <Routes>
            {/* <Route exact path="/" element={<Login />} /> */}
            <Route exact path="/" element={<SignIn />} />
            {/*<Route exact path="/" element={<OpenUserDashboard />} />*/}
            <Route
              path="/authenticatedViewWrapper/*"
              element={<AuthenticatedViewWrapper />}
            />
            <Route path="/signIn" element={<SignIn />} />
            <Route path="/signInNdi" element={<SignInNdi />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/ndiSignUp" element={<NDISignUp />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/guardianConsent" element={<GuardianConsent />} />
            <Route path="/*" element={<PageNotFound />} />
            <Route path="/trackApplication" element={<TrackApplication />} />
            <Route path="/trackApplicationDemo" element={<TrackApplicationDemo />} />
            <Route path="/certificateDetails/*" element={<CertificateDetails />} />
            <Route path="/letterOfExemption/*" element={<LetterOfExemption />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
