import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Box, Stack, Typography, Dialog, DialogActions, CardMedia, useTheme} from "@mui/material";
import nsEnrollment from "../../services/ns.registration.service";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {useTranslation,i18next} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import GyalsungLogo from "../../assets/logo/Desuung-Logo.jpg";
import { useLocation } from "react-router-dom";
import { Buffer } from 'buffer';
import moment from "moment";
import WebsiteHeader from "../Home/WebsiteHeader";

import profileService from "../../services/profile.service";
import {tokens} from "../../theme";
const CertificateDetails = () => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const [applicationDetail, setApplicationDetail] = useState(false);
    const [cid, setCid] = useState("");
    const [photoUrl, setPhotoUrl] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const location = useLocation();
    const [personalNo, setPersonalNo] = useState(null);
    const colors = tokens(theme.palette.mode);


    const [loading, setLoading] = useState(false);
    const dateFormat = "MMMM DD, YYYY";
    const timeFormat = "hh:mm A";

    useEffect(() => {
        let language = localStorage.getItem("language");

        const pathSegments = location.pathname.split("/");

        const personalNo = pathSegments[pathSegments.length - 1];

        const decodedPersonalNo = Buffer.from(personalNo, "base64").toString("utf8");
        setPersonalNo(decodedPersonalNo);

        getApplicantDetail(decodedPersonalNo).then(r => {});
    }, []);

    const getApplicantDetail = async (decodedPersonalNo) => {
        setLoading(true);
        await nsEnrollment.trackApplicantDetailByPK(decodedPersonalNo).then(
            (response) => {
                setApplicationDetail(response.data)
                setCid(response.data.cid);
            },
            (error) => {
            }
        );
        setLoading(false);
    };

    useEffect(() => {
        if(cid!==""){
            getCensusPhoto(cid);
        }

    }, [cid]);

    const getCensusPhoto = async (cidNumber) => {
        await profileService
            .getWhiteListCensusImage(cidNumber)
            .then((res) => {
                setPhotoUrl(`data:image/png;base64,${res.data}`);
            })
            .catch((error) => {
                // alert("Photo not available");
            });
    };
    

    return (
        <>
            <Box bgcolor="white" position={"relative"}>
                 <WebsiteHeader initialY={"-10vh"} animateY={"0vh"}/>
                <Box
                    display="flex"
                    justifyContent="center"
                    height="100vh"
                    alignItems="center"
                    position={"relative"}
                >
                    <Box
                        boxShadow={3}
                        borderRadius={2}
                        display="flex"
                        flexDirection="column"
                        width={{md: "60%", sx: "100%"}}
                        sx={{
                            background: "rgba(255, 255, 255, 0.77)",
                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                            backdropFilter: "blur(5px)",
                            "*::WebkitBackdropFilter": "blur(5px)",
                            border: "1px solid rgba(255, 255, 255, 1)",
                        }}
                    >
                        <Box
                            p={{xs: 5, sm: 5}}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <>
                                <Stack spacing={1}>
                                    <Stack
                                        spacing={6}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        spacing={6}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        spacing={6}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography sx={{color: "black"}}>
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems={"center"} // Ensures alignment to the start of the Stack
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: "15px", // space between images
                                            }}
                                        >
                                            {(photoUrl==null) && <Typography sx={{ color: "black" }}>
                                                <img
                                                    src={GyalsungLogo}
                                                    width="150"
                                                    height="120"
                                                    alt="NDI Logo"
                                                    style={{ marginRight: "10px" }}
                                                />
                                            </Typography>}
                                            {(photoUrl!==null) && <img
                                                src={photoUrl}
                                                alt="User Photo"
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "50%",
                                                    objectFit: "cover",
                                                    border: "2px solid #FFC300",
                                                }}
                                            />}
                                        </Box>
                                    </Stack>

                                    <Stack
                                        direction={{xs: "column", sm: "row"}}
                                        spacing={3}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gap={2}
                                        >

                                            <Table aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={2} // Span across the table width if needed
                                                            align="center" // Center the header text
                                                            sx={{
                                                                backgroundColor:"#678b3e", // Light gray background for header
                                                                color: "#000", // Black text color
                                                                fontWeight: "bold", // Bold font
                                                                fontSize: "18px", // Slightly larger font size
                                                                padding: "10px", // Add padding for better spacing
                                                                borderBottom: "2px solid #ddd", // Optional bottom border for distinction
                                                            }}
                                                        >
                                                            <Typography component="div">
                                                                National Service Training Details
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography><strong>CID</strong>: {applicationDetail.cid}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography><strong>Personal Number</strong>: {personalNo}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography><strong>Name</strong>:  {applicationDetail.fullName}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography><strong>Batch</strong>  {applicationDetail.eeIntakeYear===null?
                                                                applicationDetail.nsRegisIntakeYear:applicationDetail.eeIntakeYear}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography><strong>Sign Up</strong>:  {applicationDetail.signupDate != null ? (
                                                                    "Signed up on " +
                                                                    moment(
                                                                        applicationDetail.signupDate
                                                                    ).format(dateFormat)
                                                                    + ", " +
                                                                    moment(
                                                                        applicationDetail.signupDate
                                                                    ).format(timeFormat)

                                                            ) : (
                                                                "N/A"
                                                            )}</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography><strong>NS Registration</strong>: {applicationDetail.nsRegistrationDate !=
                                                            null ? (
                                                                        "Registered on " +
                                                                        moment(
                                                                            applicationDetail.nsRegistrationDate
                                                                        ).format(dateFormat)
                                                                        + ", " +
                                                                        moment(
                                                                            applicationDetail.nsRegistrationDate
                                                                        ).format(timeFormat)
                                                            ) : (
                                                                "N/A"
                                                            )}</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography>
                                                                <strong>Early Enlistment</strong>:  {applicationDetail.eeApplicationDate != null ? (
                                                                        "Registered on "
                                                                        + moment(
                                                                            applicationDetail.eeApplicationDate
                                                                        ).format(dateFormat)+
                                                                        ", " +
                                                                        moment(
                                                                            applicationDetail.eeApplicationDate
                                                                        ).format(timeFormat)
                                                            ) : (
                                                                "N/A"
                                                            )}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography><strong>Training Duration</strong>: {applicationDetail.trainingFromDuration + " To " +
                                                                applicationDetail.trainingToDuration }</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography><strong>Academy</strong>: {applicationDetail.academy}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography><strong>Training Status</strong>: {applicationDetail.trainingCompletionStatus==="C"?"Completed":"Not Completed"}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CertificateDetails;
